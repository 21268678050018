import React, { useEffect, useState } from "react"

// Provider
import { useTheme } from "../../context/ThemeContext"

// Hooks & Utils
import { UseShopifyDataQuery } from "../../hooks/shopifyData"
import lazyLoad from "../../utilities/handleLazyLoad"

// Data
import MEAL_PLANS_DATA from "../../../static/data/products/meal-plans"
import WORKOUT_PLANS_DATA from "../../../static/data/products/workout-plans"

// Components
import Loader from "../../components/Loader/Loader"
import BannerSection from "../../components/Banners/BannerSection"
import NavCardMenu from "../../components/Navigation/NavCardMenu"
import ProductBoxSection from "../../components/ProductBox/ProductBoxSection"
const Seo = lazyLoad(() => import("../../components/Seo/Seo"))
const ModalQuickBuy = lazyLoad(() => import("../../components/Modals/ModalQuickBuy"))
const ScrollTopButton = lazyLoad(() => import("../../components/Scrollable/ScrollTopButton"))
const Testimonial = lazyLoad(() => import("../../components/Sliders/Testimonial"))
const RockYourLifeBox = lazyLoad(() => import("../../components/Box/RockYourLifeBox"))

const CategoryPrograms = () => {
  const shopifyData = UseShopifyDataQuery()
  const { pageLoader, shipping, country } = useTheme()

  /*============================
    CUSTOM GTM EVENT
  =============================*/

  useEffect(() => {
    if (!shopifyData && !shopifyData.length) return

    // set custom GTM event

    let items = []
    Object.keys(MEAL_PLANS_DATA).forEach(item => {
      shopifyData.forEach(product => {
        if (product.shopifyId === item) {
          items = [
            ...items,
            {
              item_id: product.variants[0].shopifyId,
              item_name: product.title,
              currency: "USD",
              item_brand: "The Betty Rocker",
              item_category: "meal-plans",
              price: +product.variants[0].price,
              quantity: 1,
            },
          ]
        }
      })
    })

    Object.keys(WORKOUT_PLANS_DATA).forEach(item => {
      shopifyData.forEach(product => {
        if (product.shopifyId === item) {
          items = [
            ...items,
            {
              item_id: product.variants[0].shopifyId,
              item_name: product.title,
              currency: "USD",
              item_brand: "The Betty Rocker",
              item_category: "workout-plan",
              price: +product.variants[0].price,
              quantity: 1,
            },
          ]
        }
      })
    })

    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_name: "programs",
          items: items,
        },
      })
    }
  }, [shopifyData])

  /*============================
   QUICK BUY MODAL
  =============================*/
  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false)

  // Data for the modal
  const [shopifyItem, setShopifyItem] = useState("")
  const [clickedProduct, setClickedProduct] = useState(false)

  // Set the shopify data for the selected product
  const handleShopify = item => {
    setShopifyItem(item)
  }

  // Set the {product/apparel}Data for the selected product
  const handleProduct = product => {
    setClickedProduct(product)
  }

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isQuickBuyModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isQuickBuyModalOpen])

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="Programs | The Betty Rocker"
        title="The Betty Rocker"
        description="Whole food recipes and Home Workouts for Your Healthy Lifestyle of Awesome!"
        url="https://store.thebettyrocker.com/category/programs/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/betty-rocker-store.png"
      />

      {/*loader*/}
      <Loader isLoader={pageLoader} />

      {/*hero*/}
      <BannerSection type="programs" country={country} />

      {/*nav cards*/}
      <NavCardMenu shipping={shipping} />
      {/*product box - WORKOUT PLANS*/}
      <ProductBoxSection
        title="workout plans"
        type="workout plan"
        productData={WORKOUT_PLANS_DATA}
        shopifyData={shopifyData}
        handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
        handleProduct={handleProduct}
        handleShopify={handleShopify}
        aboveTheFold={true}
      />

      {/*rock your life*/}
      <RockYourLifeBox />

      {/*product box - MEAL PLANS*/}
      <ProductBoxSection
        title="meal plans"
        type="meal plan"
        productData={MEAL_PLANS_DATA}
        shopifyData={shopifyData}
        handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
        handleProduct={handleProduct}
        handleShopify={handleShopify}
      />

      {/*testimonials*/}
      <Testimonial type="programs" shipping={shipping} />

      {/*scroll to top button*/}
      <ScrollTopButton isSupplement={false} handleActiveTab={() => {}} isButtonOnly={true} tabs={[]} />

      {isQuickBuyModalOpen && (
        <ModalQuickBuy
          handleCloseQuickBuyModal={() => setIsQuickBuyModalOpen(false)}
          shopifyProduct={shopifyItem}
          product={clickedProduct}
          isCart={false}
        />
      )}
    </>
  )
}

export default CategoryPrograms
